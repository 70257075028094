// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBEV0SFhjNF_vZy2UWMi61pvMzqsWrfi7E",
    authDomain: "sbhq-5aab0.firebaseapp.com",
    databaseURL: "https://sbhq-5aab0.firebaseio.com",
    projectId: "sbhq-5aab0",
    storageBucket: "",
    messagingSenderId: "789256253264",
    appId: "1:789256253264:web:cfd32a9536e9a721"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
