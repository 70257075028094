export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBEV0SFhjNF_vZy2UWMi61pvMzqsWrfi7E",
    authDomain: "sbhq-5aab0.firebaseapp.com",
    databaseURL: "https://sbhq-5aab0.firebaseio.com",
    projectId: "sbhq-5aab0",
    storageBucket: "",
    messagingSenderId: "789256253264",
    appId: "1:789256253264:web:cfd32a9536e9a721"
  }
};
