import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ContactData } from "./contact.model";
import { AngularFirestore } from "@angular/fire/firestore";
import { UiService } from "../shared/ui.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"]
})
export class ContactComponent implements OnInit {
  private submitted = false;
  constructor(private db: AngularFirestore, private uiService: UiService) {}

  ngOnInit() {}
  onSubmitContact(form: NgForm, payload: ContactData) {
    const { name, email, phone, comment } = form.value;
    const date = Date();
    let formRequest = { name, email, phone, comment, date };
    this.db.collection("/contact").add(formRequest);
    form.reset();
    this.uiService.showSnackbar("Message Received", null, 3000);
  }
}
